import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import useAudio from '../../hooks/use-audio'
import { Link } from 'gatsby'
import ReactCompareImage from 'react-compare-image';

const PajaroPage = () => {
  useAudio("https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/pajaros-y-gente-xocen.mp3")
  return (
    <Layout>
      <nav class="navbar">
        <Link class="navbar-brand" to="/">Volver</Link>
      </nav>
      <div className="row justify-content-end">
        <div className="col-12 col-md-8">
          <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-6.jpg" alt="Arbol" className="img-fluid" />
        </div>
      </div>
      <SEO title="Pájaro" />
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <h1>Acto pájaro</h1>
          <p>
            Los pájaros como los niños son los que abandonarán el mundo antes del fin, así lo cuenta Fermín Dzib (1991) en el libro <em>Xocén, El pueblo en el Centro del Mundo</em> <span className="no-wrap">(Terán y Rasmussen, 2005):</span>
          </p>
          <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-35.jpg" alt="Parrafo" />
          <p>
            Son los cantos de los pájaros con los que amanece el mundo.<br />
            <br /> En su canto va dicho que algo está por suceder, una visita, un mal augurio, la llegada de las lluvias, la advertencia de algo que todavía no somos capaces de ver. <br />
            <br />La sabiduría del pájaro. <br />
            <br />El
        pájaro agorero. Aquel que se posa en las casas y con su presencia anuncia la llegada de los <em>pixanes</em>, las almas de los difuntos que llegan de visita.

        <br />
            <br /> El nido colgante de una yuya se asienta como proclama de las tempestades venideras,
        su largo indica las lluvias que vendrán o bien si la sequía es lo que se espera, en su tejido se deja ver el impacto con que vendrán tormentas o huracanes eso lo saben bien aquellos que viven entre los pájaros, que hablan el lenguaje
        de las aves.
                </p>
          <br />
          <ReactCompareImage leftImage="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-16.jpg" rightImage="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-21.jpg" />;
        </div>
      </div>
    </Layout>
  )
}

export default PajaroPage
